.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  padding: 0;
  margin: 0;
  /* background-color: aliceblue; */
  background-color: #f1f5f6;
}

.form-group.required .control-label:after {
  content: "*";
  color: red;
}

@media (min-width: 1450px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1450px;
  }
}

