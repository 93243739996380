.home {
  width: 100%;
  padding: 60px 0 100px;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.headerContainer {
  width: auto;
  /* margin-left: 50px; */
}


@media only screen and (max-width: 620px) {
  .home {
    padding: 25px 0 40px;
    justify-content: center;
    align-items: center;
  }

  .headerContainer {
    margin-left: 0px;
    border-radius: 10px;
    padding: 10px;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}


.formLogin {
  width: 70%;
  margin: 10px auto;
}

.btn.btn-primary {
  background: #4c8fdb !important;
  border: 1px solid #4c8fdb !important;
  color: #fff !important;
}

.login-form {
  width: 35%;
  margin: 5px auto;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

@media screen and (max-width: 992px) {
  .login-form {
    width: 70%;
  }
}

.btn-info {
  color: #fff !important;
  /* background-color: #5cc563 !important; */
  background-color: #559acb !important;
}

.exchangeRates {
  padding-bottom: 80px;
}

.date {
  background: #f5f9f9;
  padding: 8px 22px;
  border-radius: 5px;
  font-size: 20px;
  letter-spacing: 2px;
  color: #515151;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  border: 1px solid;
  transition: .3s all;
}


.date:hover {
  background-color: #f0f2f5;
}


.btn-download {
  font-size: 20px;
  color: #515151;
  margin: 20px 5px 25px;
  cursor: pointer;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px 10px;
  transition: .3s all;
  background-color: #4280de;
  color: #fff
    /*
  background-color: #f0f2f5;
  color: #515151;*/
}


.btn-download svg {
  color: #fff;
  transition: .3s all;
}


@media screen and (max-width: 500px) {
  .date {
    font-size: 16px;
    padding: 8px 15px;
    width: 145px;
  }

  .header_exchangeRates {
    font-size: 18px;
  }

  .btn-download {
    font-size: 16px;
    margin: 0px 1px 15px;
    width: 150px;
  }
}

/* .btn-download:hover {
  background-color: #fff;
} */

.btn-download:hover {
  color: #4280de;
  background-color: #fff;
}

.btn-download:hover svg {
  color: #4280de;
}

.search-input-moh {
  width: 320px !important;
  height: 40px;
  font-size: 14px !important;
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  .search-input-moh {
    width: 40% !important;
    font-size: 12px;
  }
}

.sortable {
  padding: 10px !important;
  font-size: 14px !important;
}



table {
  background-color: #fff;
}


/* modals */

.w-half-modal {
  width: 48%;
  /* padding-left: 3px;
  padding-right: 3px; */
}

/* @media (max-width: 768px) {
  .react-bootstrap-table {
    overflow-x: auto;
  }
} */


.currency-conversion-form {
  max-width: 500px;
  /* margin: 0 auto; */
  width: 55%;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.header_home_conversion {
  width: 45%;
}

@media screen and (max-width: 992px) {
  .currency-conversion-form {
    /* max-width: 500px; */
    width: 100%;
  }

  .header_home_conversion {
    width: 100%;
  }
}

.form_convert_money {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 30px;
  border: 2px solid #b99653;
  border-radius: 20px;
  margin: 20px 0;
}

.convert_money {
  width: 100%;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  background: #b99653;
  margin: 30px 0;
  transition: .5s all;
}

.convert_money:hover {
  background: #b99553b6;
}


.form_convert_money select,
.form_convert_money input[type='number'] {
  width: 100%;
  padding: 5px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form_convert_money select {
  width: 48% !important;
}

/* button {
  display: block;
  margin-top: 15px;
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
} */