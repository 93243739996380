.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #cbcbcb;
}

.footer ul.list-links {
  width: 80%;
  padding: 0;
  margin: 0;
}

.socialMedia {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.socialMedia svg {
  /* color: #676767; */
  margin: 5px;
  font-size: 35px;
  cursor: pointer;
  transition: .3s all;
}
.socialMedia svg:hover {
  color: #676767;
}
.footer a {
  color: #616161;
  text-decoration: none;
  margin: 5px;
  padding: 3px;
  font-weight: 600;
  transition: .3s all;
  font-size: 14px;
}

.footer a:hover {
  color: #000;
}


@media screen and (max-width: 1200px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer ul.list-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 15px;
  }

  .socialMedia {
    margin-bottom: 10px;
  }
}

.copyRight {
  font-size: 25px;
  text-align: center;
  padding: 20px 0;
  margin: 0;
}


.instagram-logo {
  color: #f56040
}

.facebook-logo {
  color: #1877f2;
}

.linkedin-logo {
  color: #0077b5;
}

.twitter-logo {
  color: #1da1f2;
}
