.navbar {
  width: 100%;
  height: 100px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  box-shadow: -1px 6px 10px 0px #e2e6e7;
}

.navbar .leftSide {
  flex: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  /* padding-left: 150px; */
}

.navbar .leftSide img {
  width: 70px;
}

.navbar .rightSide {
  flex: 85%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1px 50px 1px;
}

@media screen and (max-width: 500px) {

  .navbar .rightSide {
    padding-right: 10px !important;
    padding: 1px 3px 1px !important;
    justify-content: flex-start !important;
  }
}

.navbar .rightSide a {
  color: #000;
  text-decoration: none;
  margin: 12px;
  position: relative;
}

.navbar .rightSide a::before {
  content: "";
  width: 0;
  height: 3px;
  background-color: #555;
  position: absolute;
  top: -5px;
  transition: .5s all;
  left: 0;
}

.navbar .rightSide a:hover::before {
  width: 100%;
}

ul {
  list-style: none;
}

/*  */
.dropdown-dad {
  padding-bottom: 30px;
}

.dropdown-nav {
  position: absolute;
  background-color: #fff;
  top: 70px;
  left: -35px;
  padding: 0px 0px 0;
  width: 200px;
  z-index: -1;
  opacity: 1;
  transition: .1s all;
  visibility: hidden;
  box-shadow: 0 1px 4px #999;
  border-radius: 3px;
}

/* .dropdown-nav:hover,
.dropdown-dad:hover + .dropdown-nav {
 */

.dropdown-nav:hover,
.dropdown-dad:hover+.dropdown-nav {
  z-index: 10;
  visibility: visible;
  top: 50px;
}

@media screen and (max-width: 1200px) {
  .dropdown-nav {
    padding: 12px 0px 0;
    transform: translateX(50%);
    left: 0;
  }

  .dropdown-nav:hover,
  .dropdown-dad:hover+.dropdown-nav {
    left: 52%;
    transform: translateX(-50%);
    padding: 0;
    border: 1px solid #eee;
    top: 30px;
  }
}

.dropdown-nav li {
  border-bottom: 1px #aaa solid;
}

.dropdown-nav li:nth-child(0) {
  border-bottom: 0px #aaa solid;
}

@media screen and (max-width: 1200px) {
  .dropdown-nav li {
    border: 0;
  }
}

.dropdown-nav a {
  padding: 10px;
  color: #777;
  display: block;
  font-size: 14px !important;
}

.dropdown-nav a:hover {
  color: #333;
}

/*  */
.navbar .rightSide button {
  background-color: transparent;
  border: none;
  color: #000;
  cursor: pointer;
}

.navbar .rightSide svg {
  /* font-size: 40px; */
}

.navbar #open {
  padding-left: 0px;
}

.navbar #open img {
  display: none;
}

.navbar #close img {
  display: inherit;
}

@media screen and (max-width: 1200px) {
  .navbar #open .hiddenLinks {
    display: flex;
    flex-direction: column;
    width: 300px;
    background: #fff;
    padding: 10px;
    position: absolute;
    top: 80px;
    border-radius: 4px;
    z-index: 2;
    box-shadow: 0px 2px 4px 0px #aaa;
  }

  .navbar #open .hiddenLinks a {
    font-size: 16px;
    padding: 3px;
    padding-right: 10px;
    display: block;
    color: #000;
    text-decoration: none;
    margin: 5px;
  }
}

.navbar #close .hiddenLinks {
  display: none;
}


@media only screen and (max-width: 1200px) {
  .navbar .rightSide a {
    width: 70px;
  }

  .navbar .leftSide {
    flex: 50%;
  }

  .navbar .rightSide {
    flex: 50%;
  }

  .navbar .fullFide {
    width: 60%;
  }
}

@media only screen and (min-width: 1200px) {
  .navbar .fullFide {
    flex: 80%
  }
}

@media only screen and (max-width: 1200px) {
  .navbar .rightSide a {
    display: none;
  }

  .navbar .rightSide {
    justify-content: flex-end;
    padding-right: 50px;
  }

  .navbar .rightSide button {
    display: inherit;
  }
}

@media screen and (max-width: 500) {
  .navbar .rightSide {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 1200px) {


  .navbar .rightSide button {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

}

.logo-img {
  width: 140px;
  object-fit: contain;
  /* height="80" width="130" style={{ objectFit: 'contain' }} */
}